<template>
  <div v-frag>
    <v-container fluid class="pa-0">
      <template v-if="loadingFinished">
        <HeaderType1 :element-props="headerProps"/>
        <!-- ÁRAJÁNLAT ELFOGADÁSA / ELUTASÍTÁSA START -->
        <template v-if="(serviceRequest.serviceRequest.warrantyType.id === 2 && serviceRequest.currentStatus === 30)
        || (serviceRequest.serviceRequest.warrantyType.id === 1 && serviceRequest.currentStatus === 23)
        || (serviceRequest.serviceRequest.warrantyType.id === 9 && serviceRequest.currentStatus === 23)">
          <v-container>
            <v-row>
              <v-col cols="12" md="6">
                <v-img :src="`${publicPath}images/arajanlat-400x300.jpg`"
                       max-width="400"
                       max-height="300"
                       aspect-ratio="1.3333"
                       class="mx-auto"
                       title="Árajánlat"
                       alt="Árajánlat"></v-img>
              </v-col>
              <v-col cols="12" md="6">
                <div class="text-h5 mb-8">
                  <template v-if="serviceRequest.serviceRequest.warrantyType.id === 2">
                    {{ $t('quoteDone')}}. {{ $t('hasActiveQuoteBox.title')}}
                  </template>
                  <template v-else>
                    {{ $t('hasActiveExpertReportBox.title')}}
                  </template>
                </div>
                <div class="text-body-1 mb-2">
                  <template v-if="serviceRequest.serviceRequest.warrantyType.id === 2">
                    {{ $t('hasActiveQuoteBox.desc')}}
                  </template>
                  <template v-else>
                    {{ $t('hasActiveExpertReportBox.desc') }}
                  </template>
                </div>
                <div class="text-body-1 mb-8">
                  <template v-if="serviceRequest.serviceRequest.warrantyType.id === 2">
                    {{ $t('hasActiveQuoteBox.dowloadSentenceText') }}
                    <span @click="downloadFile(quoteDoc)"
                          style="cursor: pointer; color: #2997FF; text-decoration: underline;">
                      {{ $t('hasActiveQuoteBox.dowloadSentenceLinkText') }}
                    </span>
                  </template>
                  <template v-else>
                    {{ $t('hasActiveExpertReportBox.dowloadSentenceText') }}
                    <span @click="downloadFile(quoteDoc)"
                          style="cursor: pointer; color: #2997FF; text-decoration: underline;">
                      {{ $t('hasActiveExpertReportBox.dowloadSentenceLinkText') }}
                    </span>
                  </template>
                </div>
                <div v-if="serviceRequest.serviceRequest.warrantyType.id === 2">
                  <v-btn text
                         color="primary"
                         class="mr-3 mb-4"
                         @click="quoteAcceptance('QUOTE')">
                    {{ $t('button.quoteAcceptText') }}
                  </v-btn>
                  <v-btn text
                         color="black"
                         class="mb-4"
                         @click="quoteRejection('QUOTE')">
                    {{ $t('button.quoteRejectText') }}
                  </v-btn>
                </div>
                <div v-else>
                  <v-btn text
                         color="primary"
                         class="mr-3 mb-4"
                         @click="quoteAcceptance('EXPERT')">
                    {{ $t('button.expertReportAcceptText') }}
                  </v-btn>
                  <v-btn text
                         color="black"
                         class="mb-4"
                         @click="quoteRejection('EXPERT')">
                    {{ $t('button.expertReportRejectText') }}
                  </v-btn>
                </div>
                <div v-if="$store.state.project.isIOS && $store.state.project.isSafari">
                  <v-alert type="warning" border="left" dense>
                    {{ $t('fileDownloadAlertText') }}
                  </v-alert>
                </div>
              </v-col>
              <v-col cols="12">
                <v-divider></v-divider>
              </v-col>
            </v-row>
          </v-container>
        </template>
            <!--v-col cols="12">
              <v-card class="fill-height">
                <v-card-title class="amber lighten-2">
                  <v-icon color="black" class="mr-4">mdi-alert-decagram-outline</v-icon>
                  <span class="subtitle-1">{{ $t('quoteDone') }}</span>
                </v-card-title>
                <v-divider></v-divider>
                <v-card-text :class="$vuetify.breakpoint.xsOnly ? 'px-3 pt-10' : 'px-6 pt-10'">
                  <div class="text-center">
                    <h1 class="font-weight-regular mb-6">{{ $t('hasActiveQuoteBox.title')}}</h1>
                    <p>{{ $t('hasActiveQuoteBox.desc')}}</p>
                    <p>{{ $t('hasActiveQuoteBox.dowloadSentenceText') }}
                      <span class="font-weight-bold" @click="downloadFile(quoteDoc)" style="cursor: pointer;">
                          {{ $t('hasActiveQuoteBox.dowloadSentenceLinkText') }}
                        </span>.</p>
                  </div>
                </v-card-text>
                <v-card-actions :class="$vuetify.breakpoint.xsOnly ? 'pa-3' : 'pa-6'">
                  <v-row justify="center" class="mx-0">
                    <v-btn class="success" @click="quoteAcceptance">{{ $t('button.quoteAcceptText') }}</v-btn>
                    <v-btn class="error" @click="quoteRejection" :class="$vuetify.breakpoint.xsOnly ? 'mb-4' : 'ml-6'">{{ $t('button.quoteRejectText') }}</v-btn>
                  </v-row>
                </v-card-actions>
              </v-card>
            </v-col-->
        <!-- ÁRAJÁNLAT ELFOGADÁSA / ELUTASÍTÁSA END -->
        <v-container>
          <!-- ADATOK START -->
          <v-row>
            <!-- KÖVETÉS STÁTUSZOK START -->
            <v-col cols="12" md="6" lg="4">
              <div class="text-h5 mb-4 pa-3">
                {{ $t('tracking') }}
              </div>
              <template v-for="(item, index) in serviceRequest.history">
                <v-row :key="`${index}r`"
                       class="mx-0"
                       align="center">
                  <v-col cols="auto">
                    <v-icon :color="item.date ? 'primary' : 'grey lighten-1'">
                      {{ item.date ? 'mdi-checkbox-marked-circle' : 'mdi-checkbox-blank-circle-outline' }}
                    </v-icon>
                  </v-col>
                  <v-col class="px-2">
                    <div class="text-subtitle-1 font-weight-bold"
                         :class="item.date ? 'black--text' : 'grey--text lighten-1'"
                         style="line-height: 1.2;">
                      {{ item.status }}
                    </div>
                    <div style="min-height: 22px;">
                      {{ item.dateToDisplay }}
                    </div>
                  </v-col>
                  <v-col cols="12" class="pa-1">
                    <v-divider :key="`${index}div`"></v-divider>
                  </v-col>
                </v-row>
              </template>
            </v-col>
            <!-- KÖVETÉS STÁTUSZOK END -->
            <!-- ADATOK START -->
            <v-col cols="12" md="6" lg="8">
              <v-row>
                <!-- KÉSZÜLÉK ADATOK START -->
                <v-col cols="12" md="6">
                  <div class="text-h5 mb-4">
                    {{ $t('deviceDetails') }}
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.productType') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.serviceRequest.productType.tradeName }}
                    </div>
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.deviceIdentifier') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.serviceRequest.uniqueIdentifierIn1 }}
                    </div>
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.repairType') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.serviceRequest.warrantyType.name }}
                    </div>
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.dateOfPurchase') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.serviceRequest.purchaseDate }}
                    </div>
                  </div>
                </v-col>
                <!-- KÉSZÜLÉK ADATOK END -->
                <!-- IGÉNYEL SZOLGÁLTATÁSOK START -->
                <v-col cols="12" md="6">
                  <div class="text-h5 mb-4">
                    {{ $t('labels.claimedServices') }}
                  </div>
                </v-col>
                <!-- IGÉNYEL SZOLGÁLTATÁSOK END -->
                <!-- HIBALEÍRÁS START -->
                <v-col cols="12">
                  <div class="text-h5 mb-4">
                    {{ $t('labels.errorDescription') }}
                  </div>
                  <div class="text-body-1 mb-6">
                    {{ serviceRequest.serviceRequest.errorDescription }}
                  </div>
                  <v-divider />
                </v-col>
                <!-- HIBALEÍRÁS END -->
                <!-- ÜGYFÉLADATOK START -->
                <v-col cols="12" md="6">
                  <div class="text-h5 mb-4">
                    {{ $t('customerDetails') }}
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.name') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.customer.lastName }} {{ serviceRequest.customer.firstName }}
                    </div>
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.phone') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.customer.phone }}
                    </div>
                  </div>
                  <div>
                    <div class="text-subtitle-1 font-weight-bold mb-2">
                      {{ $t('labels.email') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.customer.email }}
                    </div>
                  </div>
                </v-col>
                <!-- ÜGYFÉLADATOK END -->
                <!-- CÍMADATOK START -->
                <v-col cols="12" md="6">
                  <!-- SZÁLLÍTÁSI START -->
                  <div class="mb-6">
                    <div class="text-h5 mb-4">
                      {{ $t('labels.shippingAddress') }}
                    </div>
                    <div class="text-body-1 mb-2">
                      {{ serviceRequest.shippingAddressData.zipCode }}
                      {{ serviceRequest.shippingAddressData.city }},
                      {{ serviceRequest.shippingAddressData.publicPlaceName }}
                      {{ serviceRequest.shippingAddressData.publicPlaceType }}
                      {{ serviceRequest.shippingAddressData.houseNumber }}.
                    </div>
                  </div>
                  <!-- SZÁLLÍTÁSI END -->
                  <!-- SZÁMLÁZÁSI START -->
                  <div>
                    <div class="text-h5 mb-4">
                      {{ $t('labels.billingData') }}
                    </div>
                    <div>
                      <div class="text-subtitle-1 font-weight-bold mb-2">
                        {{ $t('labels.billingName') }}
                      </div>
                      <div class="text-body-1 mb-2">
                        {{ serviceRequest.billingAddressData.billingName }}
                      </div>
                      <div class="text-subtitle-1 font-weight-bold mb-2">
                        {{ $t('labels.billingAddress') }}
                      </div>
                      <div class="text-body-1 mb-2">
                        {{ serviceRequest.billingAddressData.zipCode }}
                        {{ serviceRequest.billingAddressData.city }},
                        {{ serviceRequest.billingAddressData.publicPlaceName }}
                        {{ serviceRequest.billingAddressData.publicPlaceType }}
                        {{ serviceRequest.billingAddressData.houseNumber }}.
                      </div>
                      <div class="text-subtitle-1 font-weight-bold mb-2">
                        {{ $t('labels.vatNumber') }}
                      </div>
                      <div class="text-body-1 mb-2">
                        {{ serviceRequest.billingAddressData.taxNumber }}
                      </div>
                    </div>
                  </div>
                  <!-- SZÁMLÁZÁSI END -->
                </v-col>
                <!-- CÍMADATOK END -->
              </v-row>
            </v-col>
            <!-- ADATOK END -->
          </v-row>
          <!-- ADATOK START -->
        </v-container>
        <!-- DOKUMENTUMOK START -->
        <v-container class="sliderBackground mb-10" fluid>
          <v-container class="py-10">
            <div class="text-h5 mb-4 px-3">
              {{ $t('downloadableDocuments') }}
            </div>
            <div v-if="$store.state.project.isIOS && $store.state.project.isSafari">
              <v-alert type="warning" border="left" dense>
                {{ $t('fileDownloadAlertText') }}
              </v-alert>
            </div>
            <v-row>
              <template v-for="(document, index) in serviceRequest.documents">
                <v-col col="12" sm="4" md="3" :key="index" v-if="document.hash && document.table">
                  <div class="text-h6 font-weight-bold mb-2">
                    {{ document.description }}
                  </div>
                  <div class="text-body-1 mb-2">
                    {{ document.createdAt.substring(0, 10) }}
                  </div>
                  <v-btn text
                         color="primary"
                         @click="downloadFile(document)">
                    {{ $t('button.download') }}
                  </v-btn>
                </v-col>
              </template>
            </v-row>
          </v-container>
        </v-container>
        <!-- DOKUMENTUMOK END -->
      </template>
      <template v-else>
        <v-container></v-container>
      </template>
    </v-container>
    <!-- LAR DIALOG START -->
    <loading-and-response-dialog :element-props.sync="loadingAndResponseDialog"
                                 v-on:on-error-click="onErrorClickInLARDialog"
                                 v-on:on-success-click="onSuccussClickInLARDialog"></loading-and-response-dialog>
    <!-- LAR DIALOG END -->
  </div>
</template>

<script>
import HeaderType1 from '@/components/basicElements/headers/HeaderType1.vue';
import LoadingAndResponseDialog from '@/components/shared/LoadingAndResponseDialog.vue';
import { getCrmToken } from '@/utils/jwtHelper';
import project from '@/store/project.module';

export default {
  name: 'TrackingDetailsPage',
  computed: {
    project() {
      return project
    }
  },
  components: {
    HeaderType1, LoadingAndResponseDialog,
  },
  props: {
    //
  },
  async beforeMount() {
    if (this.$route.params.vs) {
      const valid = await this.callTokenValidator(this.$route.params.vs);
      if (valid) {
        this.serviceRequest = await this.getRequestById(this.crmRequestId);
        if (this.serviceRequest !== {}) {
          this.headerProps.translations[0].title = `Javítási azonosító: ${this.crmRequestId}`;
          this.serviceRequest.history = await this.getRequestHistoryById(this.crmRequestId);
          this.serviceRequest.documents = await this.getRequestDocumentsById(this.crmRequestId);
          await this.setHistory();
          if (this.serviceRequest.serviceRequest.warrantyType.id === 2) {
            this.hasActiveQuote = await this.checkHasActiveQuote();
            if (this.serviceRequest.currentStatus === 30) {
              this.quoteDoc = await this.findQuote();
            }
          } else {
            if (this.serviceRequest.currentStatus === 23) {
              this.expertDoc = this.findExpertReport();
            }
          }
          this.loadingFinished = true;
          this.closeLARDialog();
        } else {
          this.changeLARDialogContentToError();
        }
      } else{
        this.changeLARDialogContentToError();
      }
    } else {
      this.changeLARDialogContentToError();
    }
  },
  data() {
    return {
      headerProps: {
        titleFont: 'text-h3',
        subtitleFont: 'text-subtitle-1',
        textColor: '#FFFFFF',
        backgroundColorTop: '#42A5F5',
        backgroundColorBottom: '#1976D2',
        images: ['/page/pic/2021/06/01/pic_60b5f1943978c_header-bg-06.jpg'],
        backgroundType: 'image',
        backgroundPosition: 'center',
        translations: [
          {
            code: 'hu',
            name: 'Magyar',
            title: 'Javítási azonosító',
            subtitle: 'Nyomonkövetés',
          },
        ],
      },
      crmRequestId: null,
      serviceRequest: {},
      hasActiveQuote: false,
      quoteDoc: {},
      loadingFinished: false,
      loadingAndResponseDialog: {
        isVisible: true,
        stepper: 1,
        loadingText: 'LOADING_MESSAGES.LOADING_IN_PROGRESS',
        successText: 'SUCCESS_MESSAGES.CRM_REQUEST_DETAILS',
        errorText: 'ERROR_MESSAGES.CRM_REQUEST_DETAILS',
        successBtnText: 'button.ok',
        errorBtnText: 'button.ok',
      },
    };
  },
  methods: {
    async initPage() {
      //
    },
    async callTokenValidator(vs) {
      const response = await this.$store.dispatch('tokenValidator', vs);
      // console.log('callTokenValidator - ', response );
      if (response.status === 200) {
        this.crmRequestId = response.data.crmRequestId;
        return true;
      }
      return false;
    },
    async getRequestById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestById', crmRequestId);
      // console.log('getRequestById response: ', response);
      if (response.status === 200) {
        return response.data;
      }
    },
    async getRequestHistoryById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestHistoryById', crmRequestId);
      // console.log('getRequestHistoryById response: ', response);
      if (response.status === 200) {
        return response.data;
      } else {
        return [];
      }
    },
    async getRequestDocumentsById(crmRequestId) {
      const response = await this.$store.dispatch('getRequestDocumentsById', crmRequestId);
      // console.log('getRequestDocumentsById response: ', response);
      if (response.status === 200) {
        return response.data;
      } else {
        return {};
      }
    },
    async setHistory() {
      // this.$store.dispatch('setRequestHistory', { warrantyType: this.serviceRequest.serviceRequest.warrantyType.id, history: this.serviceRequest.history})
      // console.log('START setRequestHistory');
      // let requestHistory = this.serviceRequest.history;
      let hasRejectedQuote = this.serviceRequest.history.findIndex(item => {
        return item.code === 34;
      });
      let hasNoAnswerQuote = this.serviceRequest.history.findIndex(item => {
        return item.code === 36;
      });
      let templateHistory = this.serviceRequest.serviceRequest.warrantyType.id === 1 ? this.$store.state.crm.warrantyRequestStatusList : this.$store.state.crm.payedRequestStatusList;
      for (let i = 0; i < templateHistory.length; i++) {
        let isContain = this.serviceRequest.history.findIndex( item => {
          return item.code === templateHistory[i].code;
        });
        if (templateHistory[i].code === 32) {
          if (hasRejectedQuote > 0) {
            //
          } else if (hasNoAnswerQuote > 0) {
            //
          } else if (isContain === -1) {
            this.serviceRequest.history.push(templateHistory[i]);
          }
        } else {
          if (isContain === -1) {
            this.serviceRequest.history.push(templateHistory[i]);
          }
        }
      }
      this.converDateToDisplayDate();
      // console.table(this.serviceRequest.history);
      const currentStatusIndex = this.findCurrentStatus(this.serviceRequest.history);
      // console.log('currentStatus: ', this.serviceRequest.history[currentStatusIndex].code);
      this.serviceRequest.currentStatus = this.serviceRequest.history[currentStatusIndex].code;
      // console.log('END setRequestHistory');
      this.loadingRequestData = false;
    },
    findCurrentStatus() {
      // console.log('findCurrentStatus');
      this.serviceRequest.history.sort((a,b) => {
        return a.code - b.code;
      })
      this.serviceRequest.history.forEach((item,index) => {
        // console.log(`${index}. status: ${item.status}, code: ${item.code}, date: ${item.date}`);
      })
      for (let i = this.serviceRequest.history.length - 1; i >=0; i--) {
        if (this.serviceRequest.history[i].date !== null) {
          return i;
        }
      }
      return 0;
    },
    converDateToDisplayDate() {
      // console.log('converDateToDisplayDate');
      for (let i = 0; i < this.serviceRequest.history.length; i++) {
        if (this.serviceRequest.history[i].date) {
          // let tempDate = new Date(this.serviceRequest.history[i].date);
          // this.serviceRequest.history[i].dateToDisplay = tempDate.toISOString().substr(0, 10);
          let tempDate = this.serviceRequest.history[i].date;
          this.serviceRequest.history[i].dateToDisplay = tempDate.substr(0, 10);
        } else {
          this.serviceRequest.history[i].dateToDisplay = '';
        }
      }
    },
    async quoteAcceptance(type) {
      this.loadingAndResponseDialog.loadingText = 'LOADING_MESSAGES.PROCESS_IN_PROGRESS';
      this.openLARDialog();
      let response = null;
      if (type === 'EXPERT') {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.ACCEPT_EXPERTREPORT';
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.ACCEPT_EXPERTREPORT';
        response = await this.$store.dispatch('AcceptExpertReport', { requestId: this.serviceRequest.serviceRequest.id, accept: true, token: getCrmToken()});
      } else {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.ACCEPT_QUOTE';
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.ACCEPT_QUOTE';
        response = await this.$store.dispatch('AcceptQuote', { requestId: this.serviceRequest.serviceRequest.id, accept: true, token: getCrmToken()});
      }
      if(response === 200) {
        this.changeLARDialogContentToSuccess();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    async quoteRejection(type) {
      this.loadingAndResponseDialog.loadingText = 'LOADING_MESSAGES.PROCESS_IN_PROGRESS';
      this.openLARDialog();
      const document = type === 'EXPERT' ? 'A Jegyzőkönyv' : 'Az ajánlat';
      let titleSuccess = `${document} elutasítása sikeres volt.`;
      let titleError = `${document} elutasítása nem sikerült.`;
      let icon = 'mdi-emoticon-sad-outline';
      // console.log(`${document} elutasítása...`);
      // this.openLoadingDialog(`${document} elutasítása folyamatban...`);
      let response = null;
      if (type === 'EXPERT') {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.REJECT_EXPERTREPORT';
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.REJECT_EXPERTREPORT';
        response = await this.$store.dispatch('AcceptExpertReport', { requestId: this.serviceRequest.serviceRequest.id, accept: false, token: getCrmToken()});
      } else {
        this.loadingAndResponseDialog.successText = 'SUCCESS_MESSAGES.REJECT_QUOTE';
        this.loadingAndResponseDialog.errorText = 'ERROR_MESSAGES.REJECT_QUOTE';
        response = await this.$store.dispatch('AcceptQuote', { requestId: this.serviceRequest.serviceRequest.id, accept: false, token: getCrmToken()});
      }
      if(response === 200) {
        this.changeLARDialogContentToSuccess();
      } else {
        this.changeLARDialogContentToError();
      }
    },
    async loghubRequestData() {
      const response = await this.$store.dispatch('getLogHubRequestDataById', this.serviceRequest.serviceRequest.id);
      this.serviceRequest.loghubRD = response.data;
    },
    async loghubRequestDeliveryNote() {
      const response = await this.$store.dispatch('getLogHubRequestDeliveryNoteById', this.serviceRequest.serviceRequest.id);
      this.serviceRequest.loghubDN = response.data;
    },
    async downloadFile(document) {
      // console.log('document: ', document);
      this.loadingAndResponseDialog.loadingText = 'LOADING_MESSAGES.DOWNLOAD_IN_PROGRESS';
      this.openLARDialog();
      if (document !== {}) {
        let fileHandler = {
          crmRequestId: this.serviceRequest.serviceRequest.id,
          table: document.table,
          hash: document.hash,
        }
        const response = await this.$store.dispatch('downloadCrmRequestDocument', { doc: fileHandler, type: 'OPEN' });
        console.log('response : ', response);
        if (![200, 201, 202].includes(response.status)) {
          this.loadingAndResponseDialog.errorText = response.message;
          this.changeLARDialogContentToError();
        } else {
          this.closeLARDialog();
        }
      } else {
        // this.downloadFileErrorDialog.visible = true;
      }
    },
    async findQuote() {
      // console.log('findQuote');
      let file = this.serviceRequest.documents.find((item) => {
        return item.description === 'Árajánlat'
      });
      return file || {};
    },
    findExpertReport() {
      let file = this.serviceRequest.documents.find((item) => {
        return item.description === 'Jegyzőkönyv'
      });
      return file || {};
    },
    async checkHasActiveQuote() {
      // console.log('checkHasActiveQuote');
      if (this.serviceRequest.documents) {
        let ajanlat = this.serviceRequest.history.find(item => item.code === 30);
        // console.log('ajanlat: ', ajanlat);
        let elfogadott = this.serviceRequest.history.find(item => item.code === 32);
        if(!elfogadott) {
          elfogadott = {date: null};
        }
        let elutasitott = this.serviceRequest.history.find(item => item.code === 34);
        if(!elutasitott) {
          elutasitott = {date: null};
        }
        let nincsvalasz = this.serviceRequest.history.find(item => item.code === 36);
        if(!nincsvalasz) {
          nincsvalasz = {date: null};
        }
        if(nincsvalasz.date || elutasitott.date || elfogadott.date) {
          return false;
        } else if (ajanlat.date) {
          return true;
        } else {
          return false;
        }
      } else {
        return false;
      }
    },
    /**
     *
     * Loading and response dialog
     *
     */
    openLARDialog() {
      this.loadingAndResponseDialogloadingText = 'LOADING_MESSAGES.LOADING_IN_PROGRESS';
      this.loadingAndResponseDialog.stepper = 1;
      this.loadingAndResponseDialog.isVisible = true;
    },
    closeLARDialog() {
      this.loadingAndResponseDialog.isVisible = false;
    },
    changeLARDialogContentToSuccess() {
      this.loadingAndResponseDialog.stepper = 2;
    },
    changeLARDialogContentToError() {
      this.loadingAndResponseDialog.stepper = 3;
    },
    onSuccussClickInLARDialog() {
      this.closeLARDialog();
      window.location.reload();
    },
    onErrorClickInLARDialog() {
      this.closeLARDialog();
    },
  },
};
</script>

<style scoped>

</style>
